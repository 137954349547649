import React from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useLogger } from "hooks";
import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
    handleClose: () => void;
}

const RevitDownloadModal = ({ handleClose }: Props): JSX.Element => {
    const { t } = useTranslation();
    const storage = getStorage();
    const logger = useLogger();

    const handleDownloadRevitPlugin = async (version: string) => {
        try {
            const fileRef = ref(
                storage,
                `gs://${process.env.REACT_APP_STORAGE_BUCKET}/fluxtools/FluxToolsForRevit${version}.msi`
            );
            const downloadURL = await getDownloadURL(fileRef);
            window.location.href = downloadURL;
        } catch (error) {
            logger.error("Error downloading file:", error);
        }
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.shared.download-revit-plugin.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("pages.shared.download-revit-plugin.description")}</Modal.Body>
            <Modal.Footer>
                <DropdownButton size="sm" title={t("pages.shared.download-revit-plugin.dropdown-download")}>
                    <Dropdown.Item onClick={() => handleDownloadRevitPlugin("2024")}>
                        {t("pages.shared.download-revit-plugin.revit-versions", { version: "2024" })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownloadRevitPlugin("2023")}>
                        {t("pages.shared.download-revit-plugin.revit-versions", { version: "2023" })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownloadRevitPlugin("2022")}>
                        {t("pages.shared.download-revit-plugin.revit-versions", { version: "2022" })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownloadRevitPlugin("2021")}>
                        {t("pages.shared.download-revit-plugin.revit-versions", { version: "2021" })}
                    </Dropdown.Item>
                </DropdownButton>
            </Modal.Footer>
        </Modal>
    );
};

export default RevitDownloadModal;
